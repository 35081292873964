import React from "react";
import MaterialTable from "@material-table/core";
import Header from "./Header";
import { ActionModal } from "./ActionModal";
import { UploadModal } from "./UploadModal";
import { SolanaService } from '../services/solana.service.ts';
import { getProjectInfo } from "./projectInfo";
import { KillLimitsActionModal } from "./KillLimitsActionModal";


export const WalletsPage = () => {
    const { useState } = React;
    const { useEffect } = React;

    const [loading, setLoadingState] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [tokenTotalBalance, setTokenTotalBalance] = useState(0);
    const [solTotalBalance, setSolTotalBalance] = useState(0);

    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showKillLimitsModal, setShowKillLimitsModal] = useState(false);
    const [showBuyModal, setShowBuyModal] = useState(false);
    const [showSellModal, setShowSellModal] = useState(false);

    const [selectedWallet, setSelectedWallet] = useState(null);
    const [selectedWalletIndex, setSelectedWalletIndex] = useState(null);
    const [labelLookup, setLabelLookup] = useState(null);

    const projectName = localStorage.getItem("project_name");
    const liquidityPoolKeys = getProjectInfo(projectName)["liquidityPoolKeys"];
    const solanaService = new SolanaService(liquidityPoolKeys);
    const totalSupply = getProjectInfo(projectName)["totalSupply"];
    const tokenName = getProjectInfo(projectName)["tokenName"];
    const tokenAddress = getProjectInfo(projectName)["tokenAddress"];


    useEffect(async () => {
        localStorage.removeItem("tableFilter")
        if (!localStorage.getItem("sk")) {
            window.location.href = "/login";
        } else if (!localStorage.getItem("project_name")) {
            window.location.href = "/select";
        } else {
            await getBalances();
        }
    }, [])

    function onKillLimitsButtonClick() {
        setShowKillLimitsModal(true);
    }

    async function getBalances() {
        setLoadingState(true);
        var sessionWallets = JSON.parse(sessionStorage.getItem("wallets"));
        if (!sessionWallets) {
            setLoadingState(false);
            return
        }
        solanaService.setWalletsMap(sessionWallets);

        var _totalTokens = 0;
        var _totalSols = 0;
        var _labels = {}

        for (var wallet of sessionWallets) {
            const _tokenBalance = await solanaService.getTokenBalance(wallet.address, tokenAddress);
            const _solBalance = await solanaService.getSOLBalance(wallet.address);

            _totalTokens += _tokenBalance;
            _totalSols += _solBalance;

            wallet.tokenBalance = _tokenBalance;
            wallet.solBalance = _solBalance;

            wallet.supplyPercentage = _tokenBalance / totalSupply * 100;
            _labels[wallet.label] = wallet.label
        }

        setLabelLookup(_labels);
        setTokenTotalBalance(_totalTokens);
        setSolTotalBalance(_totalSols);

        setWallets(sessionWallets);
        
        setLoadingState(false);
    }

    function onBuyButtonClick(wallet, walletIndex) {
        setSelectedWallet(wallet);
        setSelectedWalletIndex(walletIndex);
        setShowBuyModal(true);
    }

    function onSellButtonClick(wallet, walletIndex) {
        setSelectedWallet(wallet);
        setSelectedWalletIndex(walletIndex);
        setShowSellModal(true);
    }

    function onUploadButtonClick() {
        setShowUploadModal(true);
    }

    function updateWalletBalance(action, tokenDiff, solDiff) {
        const dataUpdate = [...wallets];
        const index = selectedWalletIndex;
        const oldWalletData = dataUpdate[index];
        var newData = JSON.parse(JSON.stringify(oldWalletData));
        if (action === "BUY") {
            newData.tokenBalance = newData.tokenBalance + tokenDiff;
            newData.solBalance = newData.solBalance - solDiff;
            setTokenTotalBalance(tokenTotalBalance + tokenDiff);
            setSolTotalBalance(solTotalBalance - solDiff);
        } else {
            newData.tokenBalance = newData.tokenBalance - tokenDiff;
            newData.solBalance = newData.solBalance + solDiff;
            setTokenTotalBalance(tokenTotalBalance - tokenDiff);
            setSolTotalBalance(solTotalBalance + solDiff);
        }
        newData.supplyPercentage = newData.tokenBalance / totalSupply * 100;

        dataUpdate[index] = newData;
        setWallets([...dataUpdate]);

        setSelectedWallet(null);
        setSelectedWalletIndex(null);

    }

    function getFilter() {
        var filterValue = localStorage.getItem("tableFilter")
        if (!filterValue) {
            return []
        } else {
            if (typeof (filterValue) === 'string') {
                return filterValue.split(",")
            } else {
                return filterValue
            }
        }
    }

    function setFilter(newFilter) {
        localStorage.removeItem("tableFilter")
        var fixedFilter = []
        for (var fv of newFilter) {
            if (!fv.includes(",")) {
                fixedFilter.push(fv)
            }
        }
        localStorage.setItem("tableFilter", fixedFilter)
    }

    const columns = [
        { title: "Address", field: "address", searchable: true, align: "left", editable: "never", sorting: false, filtering: false },
        { title: "Label", field: "label", searchable: true, align: "left", editable: "never", sorting: false, filtering: true, lookup: labelLookup, defaultFilter: labelLookup ? getFilter() : null },
        {
            title: `${tokenName} Balance`, field: "tokenBalance", searchable: false, align: "left", editable: "never", filtering: false, render: rowData => {
                return <div>{rowData.tokenBalance.toFixed(1)}</div>
            }
        },
        {
            title: `Supply %`, field: "supplyPercentage", searchable: false, align: "left", editable: "never", filtering: false, render: rowData => {
                return <div>{rowData.supplyPercentage.toFixed(2)}</div>
            }
        },
        {
            title: "SOL Balance", field: "solBalance", searchable: false, align: "left", editable: "never", filtering: false, render: rowData => {
                return <div>{rowData.solBalance.toFixed(2)}</div>
            }
        }
    ];

    return <div>
        {<Header />}
        {
            loading
                ?
                <div className="container loading-spinner pt-5 mt-5" style={{ width: "10rem", height: "10rem" }}>
                    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
                        <path fill="#919191" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                </div>
                :
                <div>
                    <div className="container pt-3 pb-3">
                        <div className="container mb-2">
                            <button type="button" className="btn btn-warning" onClick={onUploadButtonClick}>Upload wallets JSON</button>
                        </div>
                        <div className="container mb-2">
                            <div>Total {tokenName} balance: <span className="bold">{tokenTotalBalance.toFixed(1)} ({(tokenTotalBalance / totalSupply * 100).toFixed(2)}% of supply)</span></div>
                            <div>Total SOL balance: <span className="bold">{solTotalBalance.toFixed(2)}</span></div>
                        </div>
                        <div className="container mb-2">
                            <button type="button" className="btn btn-danger" onClick={onKillLimitsButtonClick}>Kill limits</button>
                        </div>
                    </div>
                    <MaterialTable
                        columns={columns}
                        data={wallets}
                        title=""
                        onFilterChange={(e) => setFilter(e[0].value)}
                        actions={[
                            {
                                icon: () => <button type="button" className="btn btn-success btn-sm">Buy</button>,
                                tooltip: `Buy ${tokenName}`,
                                onClick: (event, rowData) => {
                                    onBuyButtonClick(rowData.address, rowData.tableData.index);
                                },
                            },
                            {
                                icon: () => <button type="button" className="btn btn-danger btn-sm">Sell</button>,
                                tooltip: `Sell ${tokenName}`,
                                onClick: (event, rowData) => {
                                    onSellButtonClick(rowData.address, rowData.tableData.index);
                                },
                            },
                        ]}
                        options={{
                            addRowPosition: "first",
                            search: true,
                            columnsButton: false,
                            filtering: true,
                            pageSize: 50,
                            pageSizeOptions: [10, 20, 50, 100, 150],
                            headerStyle: {
                                fontWeight: "bold"
                            },
                            filterCellStyle: {
                                paddingTop: "0px",
                                paddingBottom: "8px",
                            }
                        }}
                    />
                </div>
        }
        <KillLimitsActionModal setShowModal={setShowKillLimitsModal} showModal={showKillLimitsModal} tokenName={tokenName} tokenAddress={tokenAddress} solanaService={solanaService} />
        <UploadModal setShowModal={setShowUploadModal} showModal={showUploadModal} getBalances={getBalances} />
        <ActionModal setShowModal={setShowBuyModal} showModal={showBuyModal} tokenName={tokenName} tokenAddress={tokenAddress} actionType="BUY" walletAddress={selectedWallet} solanaService={solanaService} callback={updateWalletBalance} />
        <ActionModal setShowModal={setShowSellModal} showModal={showSellModal} tokenName={tokenName} tokenAddress={tokenAddress} actionType="SELL" walletAddress={selectedWallet} solanaService={solanaService} callback={updateWalletBalance} />
    </div>;
} 